import React, { useEffect, useRef, useState } from 'react'
import Base from './Base'
import ContestStructure from './ContestStructure'

import Avatar1 from '../assets/images/Avatar1.png'
import Avatar2 from '../assets/images/Avatar2.png'
import Avatar3 from '../assets/images/Avatar3.png'
import { useParams } from 'react-router'
import { useTheme } from '../ThemeContext/ThemeContext'
import { contestDetail, joinedContestUserByContestId } from '../constants/axios'
import ContestDetailJoined from './ContestDetailJoined'
import { RWebShare } from 'react-web-share'
import NotAuthorizedUser from './NotAuthorizedUser'
import CustomLoader from '../CommonComponent/CustomLoader'
import Loader from './Loader'
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify'
import url from '../constants/url'
import { useTranslation } from "react-i18next";

const ContestDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const types = [t('contest.details'), t('contest.players'), t('contest.rules')]
    const [active1, setActive1] = useState(types[0])
    const [loader, setLoader] = useState(false);

    const [allData, setAllData] = useState([])
    const [contestPlayer, setContestPlayer] = useState([])
    const [captainData, setCaptainData] = useState(undefined)
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1)
    const param = useParams()
    console.log("param", param);
    const globalValues = useTheme()

   

    // useEffect(() => {

    //     const handleBackButton = (event) => {
    //         // event.preventDefault();
    //         console.log('Back button pressed!');
    //         toast("back press")
    //         navigate(-1)

    //       // Implement your custom back button handling logic here
    //       // For example, you can navigate the user to a specific page or show a modal
    //     };

    //     // window.history.pushState(null, null, window.location.pathname);
    //     window.onpopstate = handleBackButton;

    //     return () => {
    //         window.onpopstate = null;
    //     };
    // }, []);



    const fetchContestPlayer = async (page) => {
        setLoader(true)

        const body = {
            search: {
                user: globalValues?.userId
            },
            pagination: {
                pageNo: page,
                recordPerPage: 10,
                sortBy: "rank",
                sortDirection: "asc"
            }
        }
        try {
            console.log("^^^^^^^^^^^^JCUBCI^^^^^^^^^^^^^^^^^^^^^^",globalValues?.companyCode);
            const res = await joinedContestUserByContestId(body, param?.id,globalValues?.companyCode)
            console.log("joinedContestUserByContestId", res?.data);
            if (res?.data?.success) {
                setTotalPage(res?.data?.totalPages)
                setContestPlayer([...contestPlayer, ...res?.data?.data])
                setCaptainData(res?.data?.captainData)
            }


        } catch (error) {


            console.log(error)
            let data = JSON.stringify({ "action": "back" });
            window.parent.postMessage(data, "*");
        }   
        setLoader(false)
    }
    const fetchContestDetails = async () => {
        setLoader(true)
        const body = {
            search: {
                user: globalValues?.userId
            },
            pagination: {
                pageNo: 1,
                recordPerPage: 10,
                sortBy: "rank",
                sortDirection: "asc"
            }
        }
       
        try {
            const res = await contestDetail(body, param?.id,globalValues?.companyCode)
            console.log(res);
            if (res?.data?.success) {
                setAllData(res?.data?.data)
            }
            setLoader(false)

        } catch (error) {
            console.log(error)
            if (error?.response.data?.data[0]?.msg)
                toast.error(error.response.data.data[0].msg)
            let data = JSON.stringify({ "action": "back" });
            window.parent.postMessage(data, "*");

        }

    }
    useEffect(() => {
        // if (active1 === types[0])
        if (globalValues?.userId) {
            fetchContestDetails()
            // else if (active1 === types[1])
            fetchContestPlayer(page)
        }
    }, [])

    const loadHandler = async () => {
        setLoader(true);
        let p = page + 1
        setPage(p)
        console.log(p)
        fetchContestPlayer(p)

        setLoader(false)

    };

    if (loader) return <Loader /> //<h2 style={{color:'white'}}>Loading..............</h2>
    if (!globalValues?.isLoggedIn) return <NotAuthorizedUser />

    return (
        <Base title={t('contest.contest_details')}>
            <h6 className='success-meessage'>{t("contest.joined_successfully")}</h6>
            <div className='buttonGroup'>
                {
                    types.map((type, index) => (
                        <div className={`contest-tab choose-text  ${active1 === type ? "active1" : ""}`} onClick={() => setActive1(type)}>{type}</div>
                    ))
                }
            </div>
            {console.log("types", types[0])}
            {
                active1 === types[0] ?
                    <>
                        <div className='contest-details' style={{ marginTop: '20px' }} >
                            <ContestDetailJoined allData={allData} />
                            <p className='text-center' style={{color:"var(--headerText)", fontSize:"12px", marginTop:'14px'}}>{t("contest.room_id_and_passcode_Will_be_shared_15_mins_before_start_time")}</p>
                        </div>
                        <div className='contest-details' style={{ padding: '0' }}>
                            <div className='user-rank'>
                                <div className='user-rank-details'>
                                    <span>{t("contest.player_name")}</span>

                                </div >
                                <div className='user-rank-details' >

                                    {allData?.winningModel != '' ? <span>{t("contest.kills")}</span> : <></>}

                                </div>
                                <div className='user-rank-details' >
                                    <span>{t("contest.rank")}</span>

                                </div>
                            </div>
                            <div className='user-rank'>
                                <div className='user-rank-details'>
                                <img src = {url.defaultPlayerLevelIcon} 
                                                style={{width:'22px', height:'22px'}}
                                                /> {" "}
                                                 {/* <img src = {url.imageUrl + captainData?.userData?.userlevel?.level?.featuredImage?.default } 
                                                style={{width:'22px', height:'22px'}}
                                                /> {" "} */}
                                    <span>{captainData?.inGameUserName}</span>

                                </div>
                                <div className='user-rank-details' >
                                    {allData?.winningModel != '' ? <span>{captainData?.kills > 0 ? captainData?.kills : "-"}</span> : ""}


                                </div>
                                <div className='user-rank-details' >
                                    <span>{captainData?.rank > 0 ? captainData?.rank : "-"}</span>

                                </div>
                            </div>

                        </div>
                        {console.log(`${globalValues?.clientId == 'demo' ? `https://rbtqa.onmobile.com/calite/home`: `https://challengesarena.com/${globalValues?.clientId}/home`}?dl=${encodeURIComponent(`${window.location.origin}${window.location.pathname}?clientId=${globalValues?.clientId}&gameCode=${globalValues.gameCode}`)}`)}
                        <RWebShare
                            data={{
                                text:t("contest.hey_join_contest_for_exciting_match_via", {shortCode: allData?.shortCode}) ,
                                title: "Join Contest",
                                url: `${globalValues?.clientId == 'demo' ? `https://rbtqa.onmobile.com/calite/home`: `https://challengesarena.com/${globalValues?.clientId}/home`}?dl=${encodeURIComponent(`${window.location.origin}${window.location.pathname}?clientId=${globalValues?.clientId}&gameCode=${globalValues.gameCode}`)}`
                            }}
                            onClick={() => console.info("share successful!")}
                        >
                            <div className='share-friends' >
                                <div className='frn-images'>
                                    <div className='frnd-avatar'><img src={Avatar1} /></div>
                                    <div className='frnd-avatar'><img src={Avatar2} /></div>
                                    <div className='frnd-avatar'><img src={Avatar3} /></div>
                                </div>
                                <h2>{t("contest.share_with_your_friends")}</h2>
                            </div>
                        </RWebShare>

                    </>
                    : active1 === types[1] ?
                        <>
                            <div className='contest-details' style={{ padding: '0', marginTop: '50px' }}>
                                <div className='user-rank'>
                                    <div className='user-rank-details'>
                                        <span>{t("contest.player_names")}</span>

                                    </div>
                                    <div className='user-rank-details' >
                                        <span>{t("contest.kills")}</span>

                                    </div>
                                    <div className='user-rank-details' >
                                        <span>{t("contest.rank")}</span>

                                    </div>
                                </div>
                                {
                                    contestPlayer?.map((player, index) => (
                                        
                                        <div className='user-rank'>
                                            <div className='user-rank-details'>
                                                {console.log(player?.userData?.userlevel?.level)}
                                                <img src = {url.imageUrl + player?.userData?.userlevel?.level?.featuredImage?.default } 
                                                style={{width:'22px', height:'22px'}}
                                                /> {" "}
                                                <span>{player?.inGameUserName}</span>

                                            </div>
                                            <div className='user-rank-details' >
                                                <span>{player?.kills > 0 ? player?.kills : "-"}</span>

                                            </div>
                                            <div className='user-rank-details' >
                                                <span>{player?.rank > 0 ? player?.rank : "-"}</span>

                                            </div>
                                        </div>
                                    ))
                                }
                                {/* {console.log("load more>>>>", page, totalPage, contestPlayer)} */}
                               

                            </div>
                            {
                                    page < totalPage ?
                                        <div className="padding-top2" style={{marginBottom:'40px'}}>
                                            <button
                                                style={{ cursor: 'pointer' }}
                                                onClick={loadHandler}>


{t("contest.load_more")}

                                            </button>
                                        </div>
                                        : ""
                                }
                        </>
                        :
                        <>
                            <div className='game-rules'>
                                <div dangerouslySetInnerHTML={{ __html: allData?.rules }} />
                            </div>
                        </>
            }

        </Base>
    )
}

export default ContestDetails