import React, { useEffect, useState } from 'react'
import Base from './Base'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '../ThemeContext/ThemeContext'
import { getTransactionId, joinContest } from '../constants/axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";

const TeamRegistrationForm = () => {
	const { t } = useTranslation();

    useEffect(() => {
        // getTransactionID()
    }, [])

    const param = useParams()
    const [showLoader, setShowLoader] = useState(false)
    const globalValues = useTheme()
    const [transactionId, setTransactionId] = useState('')
    const [teamName, setTeamName] = useState('')
    const [player2, setPlayer2] = useState('')
    const [player3, setPlayer3] = useState('')
    const [player4, setPlayer4] = useState('')
    const [extra, setExtra] = useState('')
    const navigate = useNavigate()

    console.log(param?.duo)
    const getTransactionID = async () => {
        setShowLoader(true)
        const res = await getTransactionId("eng", globalValues?.clientId)
        console.log("transactionId response", res?.data?.upsTrxId)
        if (res?.data) {
            setTransactionId(res?.data?.upsTrxId)
        }
        setShowLoader(false)
    }
    const saveTeamHandler = async () => {
        console.log(player2)
        let players = []
        let team = ''
        let error = ''
        if (teamName == '') {
            error = t('team_registration.enter_team_name')
        }
        else {
            team = teamName
        }
        if (param?.duo == 2 || param?.duo == 3) {
            if (player2 == '') {
                error = t('team_registration.enter_player_name')
            }
            else {
                players.push({ 'in_game_name': player2 })
            }
            if (extra !== '') {

                players.push({ 'in_game_name': extra })
            }
        }
        else if (param?.duo == 4 || param?.duo == 5) {
            if (player2 == '') {
                error =  t('team_registration.enter_player_name')
            }
            else {
                players.push({ 'in_game_name': player2 })
            }
            if (player3 == '') {
                error =  t('team_registration.enter_player_name')
            }
            else {
                players.push({ 'in_game_name': player3 })
            }
            if (player4 == '') {
                error =  t('team_registration.enter_player_name')
            }
            else {
                players.push({ 'in_game_name': player4 })
            }
            if (extra !== '') {

                players.push({ 'in_game_name': extra })
            }

        }
        console.log(team, players)
        if (error !== '') {
            toast.error(error)
        }
        else {
            const date = new Date()
            const timeStamp = Date.now()
            console.log(date)
            let body = {

                inGameUserName: globalValues?.inNameGame,
                user: globalValues?.userId,
                contest: param?.id,
                joinedDate: moment(new Date()),
                JoinedTimestamp: timeStamp,
                clientId: globalValues?.clientId,
                upsTrxId: transactionId // change
            }
            if (team != '') {
                body = { ...body, teamName: team }
            }
            if (players.length > 0) {
                body = { ...body, players_data: players }
            }
            console.log(body)
            try {
                const res = await joinContest(body)
                console.log(res);
                if (res?.data?.success)
                    navigate(`/contest-details/${param?.id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`, { state: { module: 'team_register' } })
                //window.location.href = `/contest-details/${param?.id}?clientId=${globalValues?.clientId}&gameCode=${globalValues?.gameCode}&authCode=${globalValues.authCode}`
            } catch (error) {
                console.log(error)
                toast(error?.response?.data?.data[0]?.msg)
                let data = JSON.stringify({ "action": "back" });
                window.parent.postMessage(data, "*");
            }
        }
    }
    const onChangeHandler = (e) => {
        let { name, value } = e.target
        console.log(name, value)
        if (name == 'teamName') setTeamName(value)
        if (name == "player2") setPlayer2(value)
        if (name == "player3") setPlayer3(value)
        if (name == "player4") setPlayer4(value)
        if (name == "extra") setExtra(value)

    }
    if (showLoader) return <h2 style={{ color: 'white' }}>{t('team_registration.loading')}</h2>
    return (
        <Base title={'team registrations'}>
           
            <div className='contest-details'>
                <span>{t('team_registration.team_name')}</span>

                <div className='apply-code' style={{ marginBottom: '0' }}>
                    <input onChange={(e) => onChangeHandler(e)} type='text' placeholder={t('team_registration.enter_team_name')} name="teamName" />
                    {/* <button>add</button> */}
                </div>
            </div>
            <div className='contest-details' style={{ marginTop: '20px' }}>
                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.captain')}</div>
                <div className='innamegame'>
                    <input value={globalValues?.inNameGame} />
                    {/* <button>add</button> */}
                </div>
            </div>
            {console.log(param?.duo, param?.duo == 2)}
            {
                param?.duo == 2 ?
                    <>
                        <div className='contest-details' style={{ marginTop: '20px' }}>
                            <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.player2')}</div>
                            <div className='innamegame'>
                                <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="player2" />
                                {/* <button>add</button> */}
                            </div>

                        </div>

                    </>
                    :
                    param?.duo == 3 ?
                        <>
                            {console.log(param?.duo == 3)}
                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.player2')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="player2" />
                                    {/* <button>add</button> */}
                                </div>

                            </div>
                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.extra')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="extra" />
                                    {/* <button>add</button> */}
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.player2')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="player2" />
                                    {/* <button>add</button> */}
                                </div>

                            </div>

                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.player3')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="player3" />
                                    {/* <button>add</button> */}
                                </div>

                            </div>
                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.player4')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="player4" />
                                    {/* <button className='apply-button'>add</button> */}
                                </div>

                            </div>
                            <div className='contest-details' style={{ marginTop: '20px' }}>
                                <div className={`status`} style={{ marginLeft: '75%' }}>{t('team_registration.extra')}</div>
                                <div className='innamegame'>
                                    <input onChange={(e) => onChangeHandler(e)} type="text" placeholder={t('team_registration.enter_ingame_name')} name="extra" />
                                    {/* <button>add</button> */}
                                </div>

                            </div>
                        </>
            }


            <button className='save-team' onClick={saveTeamHandler}>{t('team_registration.save_team')}</button>
         
        </Base>
    )
}

export default TeamRegistrationForm