import React, { useState } from 'react'
import BasePopUp from './BasePopUp'
import url from '../constants/url'
import Close from "../assets/images/close.svg";
import { useTranslation } from "react-i18next";

const ShowWinnersPopup = ({allWinners,cancelWinner}) => {
  const { t } = useTranslation();

    const types = [t("contest.prize_pool")]
    const [active, setActive] = useState(types[0])
  return (
    <BasePopUp>
        <h2 className='choose-text'>{t("show_winners_popup.winning_breakup")}</h2>
        <div
                className="closeButton"
                onClick={() => cancelWinner()}
              >
                {/* <img src={Close} alt="" /> */}    <div className='close'>X</div>
              </div>
        <div className='winning-breakup'>
        <div className='buttonGroup'>
            {
                types.map(type =>(
                    <div key={type} className={`tab ${active === type ? "active" : ""}`} onClick={()=> setActive(type)}>{type}</div>
                ))
            }
                
        </div>
        <div className='first second'> 
                <div style={{width:'50%'}}>{t("show_winners_popup.rank")}</div>
                <div style={{width:'50%'}}><img src={url.imageUrl + allWinners?.currency[0]?.outCurrency?.img?.default}/> {t("show_winners_popup.reward")}</div>
        </div>
        {active===types[0] ? allWinners?.prizePoolInfo?.map((item, index)=> (
                <div className='first'> 
                        <div style={{width:'50%'}}>{item?.rankTo !== "0" && item?.rankTo != item?.rankFrom
                                    ? item?.rankFrom + " - " + item?.rankTo
                                    : item?.rankFrom}</div>
                        <div style={{width:'50%'}}>{item?.amount}</div>
                </div>
        )): (
            allWinners?.pointpool?.map((item, index)=> (
                <div className='first'> 
                        <div style={{width:'50%'}}>{item?.rankTo !== "0" && item?.rankTo != item?.rankFrom
                                    ? item?.rankFrom + " - " + item?.rankTo
                                    : item?.rankFrom}</div>
                        <div style={{width:'50%'}}>{item?.amount}</div>
                </div>))
        )}
        
       
        </div>
    </BasePopUp>
  )
}

export default ShowWinnersPopup